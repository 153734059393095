import { Injectable } from '@angular/core';
import { Apollo,ApolloBase,gql } from 'apollo-angular';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
private apolloParkSlot:ApolloBase
private apolloParkArea:ApolloBase
constructor(private _apollo:Apollo) {
  this.apolloParkArea=_apollo.use('parkArea');
  this.apolloParkSlot=_apollo.use('parkSlot');
 }

 getParkArea(parkAreaId:any): Observable<any>{
  let parkAreas=gql`
  {
    parkareas(id: "${parkAreaId}") {
      _id
      location
      name
      parkPlanUrl
      company {
        name
        description
        avatar
      }
      availableParkSlotsCount
      occupancyRate
    }
  }
  `
  return this.apolloParkArea.watchQuery<any>({query:parkAreas}).valueChanges;

 }
 getParkAreas(companyId:any): Observable<any>{
  let parkAreas=gql`
  {
    parkareas(id: "${companyId}") {
      _id
      location
      name
      parkPlanUrl
      parkingLimit
      company {
        name
        avatar
      }
      totalParkSlotsCount
      availableParkSlotsCount
      occupancyRate
    }
  }
  `
  return this.apolloParkArea.watchQuery<any>({query:parkAreas}).valueChanges;

 }
 getParkSlotsLogs(parkAreaId:any): Observable<any>{
  let parkAreas=gql`
  {
    parkarea(id: "${parkAreaId}") {
      parkslot {
        _id
        status
        slotNumber
        location
        type
        deviceCountinuingParkingTime
      }
    }
  }
  `
  return this.apolloParkSlot.watchQuery<any>({query:parkAreas, pollInterval: 30000,fetchPolicy: 'network-only'}).valueChanges;

 }
 getParkSlotsLogsByCompany(companyId:any): Observable<any>{
  let parkingLots=gql`
  {
    parkslots(id: "${companyId}") {
        _id
        status
        slotNumber
        location
        type
        deviceCountinuingParkingTime
    }
  }
  `
  return this.apolloParkSlot.watchQuery<any>({query:parkingLots, pollInterval: 30000,fetchPolicy: 'network-only'}).valueChanges;

 }
 getParkSlotLogs(parkSlotId:any): Observable<any>{
  let parkAreas=gql`
  {parkslot(id:"${parkSlotId}") {
    device{
        sensorId
        parkingStartTime
        parkingEndTime
        parkingTime
        parkingLimitState
      }
  }
  }
  `
  return this.apolloParkSlot.watchQuery<any>({query:parkAreas}).valueChanges;

 }
 getFilteredParkSlotLogs(parkSlotId:any,parkingLimitState:any,from:String,to:String,offset:number,limit:number): Observable<any>{
  const fromDate=from+"T00:00:00.000Z";
  const toDate=to+"T23:59:59.000Z";
  let parkAreas= parkingLimitState=='all' ? gql`
  {
    devices(id: "${parkSlotId}",from: "${fromDate}",to: "${toDate}", parkingLimitState: null, offset: ${offset}, limit: ${limit}) {
      data {
        parkingStartTime
        parkingEndTime
        parkingTime
        parkingLimitState
        parkSlot {
          slotNumber
          type  
        }
      }
      paginationMetaData {
        total
        page
        pages
      }
    }
  }
  `
  :gql`
  {
    devices(id: "${parkSlotId}",from: "${fromDate}",to: "${toDate}", parkingLimitState: "${parkingLimitState}", offset: 0, limit: ${limit}) {
      data {
        parkingStartTime
        parkingEndTime
        parkingTime
        parkingLimitState
        parkSlot {
          slotNumber
          type  
        }
      }
      paginationMetaData {
        total
        page
        pages
      }
    }
  }
  `
  return this.apolloParkSlot.watchQuery<any>({query:parkAreas,fetchPolicy: 'network-only'}).valueChanges;

 }
 getDashboardStatistics(companyId:any,from:any,to:any){
  let statistics=gql`
  {
    dashboard(id: "${companyId}",from: "${from}",to:  "${to}") {
      name
      avatar
      totalVehicleCount
      totalParkingTime
      meanParkingTime
      parkingRatio
      instantParkingRatio
      totalMonthlyVehicleInCount
      totalVehicleInCount
      totalVehicleOutCount
      totalVehiclePenalties
      monthlyParkingRatio
    }
  }
  `
  return this.apolloParkSlot.watchQuery<any>({query:statistics,pollInterval:30000}).valueChanges;
 }

 getStatistics(companyId:any,from:any,to:any){
  let statistics=gql`
  {
    statistics(id: "${companyId}",from: "${from}",to:  "${to}") {
      name
      totalPenalty {
        day
        value  
      }
    totalVehicle {
        day
        value  
      }
    parkingRatio {
        day
        value  
      }
    }
  }
  `
  return this.apolloParkSlot.watchQuery<any>({query:statistics}).valueChanges;
 }

}


