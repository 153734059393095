import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './network/api.service';

@Injectable({
  providedIn: 'root'
})
export class ParkSlotsService {

  baseUrl:String=environment.parkAreaApiUrl;

  constructor(
    private _api:ApiService
  ) { }

   get() {
    const result =  this._api.get(this.baseUrl+"/parkslot");
    return result;
  }
   getByParkAreaByType(parkAreaId:any,type:any,limit:any,skip:any) {
    const result =  this._api.get(this.baseUrl+"/parkslots"+"/" +parkAreaId+"/"+type+"?limit=" + limit + '&skip=' + skip);
    return result;
  }
   getByParkArea(parkAreaId:any,limit:any,skip:any) {
   const result =  this._api.get(this.baseUrl+"/parkslots"+"/"+parkAreaId+"?limit=" + limit + '&skip=' + skip);
   return result;
 }
   getById(id:any) {
    const result =  this._api.get(this.baseUrl+"/parkslot/"+id);
    return result;
  }
   post(parkSlot:any) {
    const result =  this._api.post(this.baseUrl+"/parkslot",parkSlot);
    return result;
  }
   bulkCreate(parkSlot:any) {
    const result =  this._api.post(this.baseUrl+"/parkslot/createParkSlots",parkSlot);
    return result;
  }
   put(parkSlot:any) {
    const result =  this._api.put(this.baseUrl+"/parkslot/"+parkSlot._id,parkSlot);
    return result;
  }
   delete(id:any) {
    const result =  this._api.delete(this.baseUrl+"/parkslot/"+id);
    return result;
  }
   bulkDelete(parkSlot:any) {
    const result =  this._api.post(this.baseUrl+"/parkslot/deleteParkSlots",parkSlot);
    return result;
  }
   getAllTickets(companyId:any,limit:any,skip:any){
   const result =  this._api.get(this.baseUrl+"/parkslot/alltickets/"+companyId+"?limit=" + limit + '&skip=' + skip);
   return result;
 }
}
